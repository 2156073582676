import { theme } from '../Theming/Theming';

export const HallmarkBorder = `
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  font-weight: ${theme.weights.bold};
  &:after {
    margin: 1rem auto 0;
    content: ' ';
    display: block;
    border-bottom: ${theme.general.hallmarkBorder};
    max-width: 50px;
  }
`;

//FULL WIDTH CONTAINER INSIDE LIMITED WIDTH
export const fullWidth = `
  @media (min-width: ${theme.general.maxWidth}) {
    margin-left: calc(-100vw / 2 + ${theme.general.maxWidth} / 2);
    margin-right: calc(-100vw / 2 + ${theme.general.maxWidth} / 2);
  }
`;

//CUSTOM SCROLL BAR
export const scrollBar = `
  overflow: auto;
  padding-right: 5px;
  ::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f9f9f9;
  }

  ::-webkit-scrollbar-thumb {
    background: #eee;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ddd;
  }
`;

export const imageOverlay = `
  position:relative;
  padding:0;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.04;
  }
`;
