import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import NewsletterField from '../../Newsletter/NewsletterField';
import Image from '@jetshop/ui/Image/Image';
import MaxWidth, { MaxWidthWrapper, Inner } from '../MaxWidth';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';

const NewsletterBoxWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  ${MaxWidthWrapper} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
  }
  .image-wrapper {
    width: 100%;
    [data-flight-image-container] {
      ${theme.below.lg} {
        padding-bottom: 50% !important;
      }
      ${theme.below.md} {
        padding-bottom: 80% !important;
      }
      ${theme.below.sm} {
        padding-bottom: 110% !important;
      }
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem ${theme.general.pagePadding.sm};
    ${theme.above.md} {
      padding: 4rem ${theme.general.pagePadding.md};
    }
    .text {
      > h2,
      > h3,
      > h4,
      > h5,
      > p {
        margin: 0 0 0.5em;
      }
    }
    > * {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }
    .newsletter-wrapper {
      > p {
        text-align: center;
      }
      input {
      }
      button {
        background: ${theme.colors.white};
        color: ${theme.colors.font};
        text-transform: uppercase;
        opacity: 1 !important;
        border-left: 2px solid ${theme.colors.font};
      }
    }
  }

  &.standard {
    .text-wrapper {
      max-width: 800px;
      margin: 0 auto;
      background: ${theme.colors.secondary};
      .form-wrapper {
        margin-top: 1rem;
        border: 2px solid ${theme.colors.font};
        > p {
          text-align: center;
        }
        input {
          background: ${theme.colors.white};
        }
        button {
          background: ${theme.colors.white};
          color: ${theme.colors.font};
          text-transform: uppercase;
          opacity: 1 !important;
          border-left: 2px solid ${theme.colors.font};
        }
      }
    }
  }

  //TRANSPARENT TYPE
  &.transparent {
    ${MaxWidthWrapper} {
      width: 100%;
    }
    > h2,
    > h3,
    > h4,
    > h5,
    > p {
      color: ${theme.colors.white};
    }
    .error {
      color: ${theme.colors.white};
    }
    .text-wrapper {
      background: none;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      text-align: center;
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        color: ${theme.colors.white};
      }
      .text {
        h5 {
          margin: 2rem 0;
        }
      }
      .newsletter-wrapper {
        background: none;
        border: none;
        input {
          background: ${theme.colors.white};
          border: none;
          margin-right: 1rem;
          ${theme.below.sm} {
            margin-right: 5px;
          }
        }
        button {
          background: ${theme.colors.white};
          color: ${theme.colors.primaryDark};
          text-transform: uppercase;
          opacity: 1 !important;
          border: none;
        }
      }
    }
  }
`;

const NewsletterBox = () => {
  const { showNewsletter, newsletter, newsletterImage } =
    useContext(SiteContentContext);

  if (!showNewsletter) return null;

  return (
    <NewsletterBoxWrapper className={true ? 'transparent' : 'standard'}>
      {newsletterImage && (
        <div className="image-wrapper">
          <Image
            aspect={'3:1'}
            sizes={[1, 1, 1 / 2, 1 / 2]}
            src={newsletterImage}
            alt={newsletterImage}
            crop={true}
            cover
          />
        </div>
      )}
      <MaxWidth>
        <Inner>
          <div className="text-wrapper">
            <div className="text">{newsletter}</div>
            <NewsletterField />
          </div>
        </Inner>
      </MaxWidth>
    </NewsletterBoxWrapper>
  );
};

export default NewsletterBox;
