import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import Image from '@jetshop/ui/Image';
import NewsletterBox from './NewsletterBox';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { getFontSize } from '../../Theming/helpers';

const FooterWrapper = styled('footer')`
  margin-top: 3%;
  background: ${theme.footer.background};
  z-index: 2;
  a {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 3rem 0 0;
    color: ${theme.footer.color};
  }
  .footer-bottom {
    margin-bottom: 3%;
    ul {
      display: flex;
      justify-content: center;
      > li {
        margin: 5px;
      }
    }
  }
`;

export const FooterContentWrapper = styled('div')`
  > ul {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 3% 0;
    > li {
      padding: 10px 5px;
      ${getFontSize(theme.typography.header.fontSize[3])}
      text-transform: uppercase;
    }
  }
`;

export const FooterLogo = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  background: ${theme.footer.logoWrapper.background};
  > div {
    width: 100%;
    max-width: 400px;
  }
`;

const Footer = () => {
  const { footer1, footerLogo, footerBottom } = useContext(SiteContentContext);
  return (
    <>
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>
        <div className="footer-content">
          <MaxWidth>
            <FooterContentWrapper>{footer1}</FooterContentWrapper>
            <div className="footer-bottom">{footerBottom}</div>
          </MaxWidth>
        </div>
      </FooterWrapper>
      {footerLogo && (
        <FooterLogo>
          <div>
            <Image aspect="2:1" src={footerLogo} />
          </div>
        </FooterLogo>
      )}
    </>
  );
};

export default Footer;
