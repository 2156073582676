import React, { useRef, useState } from 'react';
import { styled } from 'linaria/react';
import { useQuery } from 'react-apollo';
import storesQuery from './StoresQuery.gql';
import MaxWidth from '../Layout/MaxWidth';
import TextContent from '../ui/TextContent';
import { theme } from '../Theming/Theming';
import CloseButton from '../ui/CloseButton';

const StoresWrapper = styled.div`
  .no-result {
    text-align: center;
  }
`;
const SearchWrapper = styled.div`
  margin: 2rem auto 1rem;
  width: 300px;
  position: relative;
  ${theme.below.sm} {
    width: 100%;
  }
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid ${theme.colors.border};
  }
  button {
    position: absolute;
    top: 12px;
    right: 10px;
  }
`;
const StoreList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
`;
const Store = styled.div`
  border-bottom: 1px solid ${theme.colors.border};
  padding: 1rem 0;
  width: 100%;
  ${theme.above.md} {
    width: 50%;
    padding: 2rem;
    &:nth-of-type(2n + 1) {
      border-right: 1px solid ${theme.colors.border};
    }
  }
  .store-name {
    margin-bottom: 1rem;
    color: ${theme.colors.primary};
  }
  .store-desc {
    margin-top: 1rem;
  }
  .content-area {
    p {
      margin: 0;
    }
  }
`;

export const Stores = () => {
  const result = useQuery(storesQuery);
  const [term, setTerm] = useState('');
  const inputRef = useRef();
  const stores = result?.data?.stores;

  if (!stores || stores.length === 0) return null;

  const hasTerm = term.length > 2;
  const hasWritten = term.length > 0;

  const storesList = hasTerm
    ? stores.filter(
        (store) =>
          (store.name && store.name.toLowerCase().search(term) !== -1) ||
          (store.address1 &&
            store.address1.toLowerCase().search(term) !== -1) ||
          (store.description &&
            store.description.toLowerCase().search(term) !== -1) ||
          (store.city && store.city.toLowerCase().search(term) !== -1)
      )
    : stores;

  function updateValue() {
    setTerm(inputRef.current.value ? inputRef.current.value.toLowerCase() : '');
  }

  function resetValue() {
    setTerm('');
    inputRef.current.value = '';
  }

  return (
    <StoresWrapper>
      <MaxWidth>
        <SearchWrapper>
          <input
            placeholder="Sök återförsäljare här.."
            type="text"
            ref={inputRef}
            onChange={updateValue}
          />
          {hasWritten && <CloseButton onClick={resetValue} />}
        </SearchWrapper>
        {storesList.length === 0 ? (
          <p className="no-result">Inget resultat</p>
        ) : (
          <StoreList>
            {storesList.map((store) => {
              return (
                <Store key={store.id}>
                  <h3 className="store-name">{store.name}</h3>
                  <TextContent
                    className="store-address"
                    content={store.address1}
                  />
                  <span className="store-city">{store.city}</span>
                  <TextContent
                    className="store-desc"
                    content={store.description}
                  />
                  <TextContent
                    className="store-contact"
                    content={store.contact}
                  />
                </Store>
              );
            })}
          </StoreList>
        )}
      </MaxWidth>
    </StoresWrapper>
  );
};
