import React, { Fragment, useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { Below } from '@jetshop/ui/Breakpoints';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import DataProvider from './Categories/CategoryProvider';
import TopBar from './TopBar/TopBar';
import DesktopHeader from './Desktop/DesktopHeader';
import DesktopNav from './Desktop/DesktopNav';
import MobileHeader from './Mobile/MobileHeader';
import MobileNav from './Mobile/MobileNav';
import CartFlyout from '../../Cart/CartFlyout';
import { theme } from '../../Theming/Theming';
import { useLocation } from 'react-router';

const DesktopHeaderWrapper = styled('header')`
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  a {
    text-decoration: none;
    &:hover {
    }
  }
  ${theme.below.sm} {
  }
`;

const DesktopHeaderSpacer = styled('div')`
  &[data-startpage='false'] {
    height: ${theme.header.height.lg + 'px'};
    ${theme.only.md} {
      height: ${theme.header.height.md + 'px'};
    }
  }
  &[data-startpage='true'] {
    height: ${theme.topBar.height.lg + 'px'};
    ${theme.only.md} {
      height: ${theme.topBar.height.md + 'px'};
    }
  }
`;

const MobileHeaderWrapper = styled('header')`
  position: fixed;
  z-index: 104;
  top: 0;
  left: 0;
  width: 100%;
  a {
    text-decoration: none;
    &:hover {
    }
  }
  ${theme.below.sm} {
  }
`;
const MobileHeaderSpacer = styled('div')`
  &[data-startpage='false'] {
    height: ${theme.header.height.sm + 'px'};
  }
  &[data-startpage='true'] {
    height: ${theme.topBar.height.sm + 'px'};
  }
`;

const Header = ({ categoryTreeRoots, categories }) => {
  const [searchOpen, setSearch] = useState(false);
  const [scrolling, setScroll] = useState(false);
  const { pathname } = useLocation();
  const isStart = pathname === '/';

  const handleScroll = (event) => {
    if (typeof window === 'undefined') return false;

    if (window.scrollY < theme.header.scrollBreak && scrolling) {
      setScroll(false);
    } else if (window.scrollY > theme.header.scrollBreak && !scrolling) {
      setScroll(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <React.Fragment>
      <Below breakpoint="lg">
        {(matches) =>
          matches ? (
            <Fragment>
              <MobileHeaderSpacer
                data-scrolling={scrolling}
                data-startpage={isStart}
              />
              <MobileHeaderWrapper id="main-header">
                <TopBar scrolling={scrolling} />
                <MobileHeader
                  searchOpen={searchOpen}
                  setSearch={setSearch}
                  scrolling={scrolling}
                  isStart={isStart}
                />
              </MobileHeaderWrapper>
              <DrawerTarget id="menuDrawer" showCover={true}>
                {({ isOpen, hideTarget }) => (
                  <MobileNav
                    categories={categories}
                    categoryTreeRoots={categoryTreeRoots}
                    onClick={hideTarget}
                    isOpen={isOpen}
                    hideTarget={hideTarget}
                  />
                )}
              </DrawerTarget>
            </Fragment>
          ) : (
            <Fragment>
              <DesktopHeaderSpacer
                data-scrolling={scrolling}
                data-startpage={isStart}
              />
              <DesktopHeaderWrapper id="main-header">
                <TopBar scrolling={scrolling} />
                <DesktopHeader
                  scrolling={scrolling}
                  searchOpen={searchOpen}
                  setSearch={setSearch}
                  isStart={isStart}
                >
                  <DesktopNav
                    scrolling={scrolling}
                    categoryTreeRoots={categoryTreeRoots}
                  />
                </DesktopHeader>
              </DesktopHeaderWrapper>
            </Fragment>
          )
        }
      </Below>
      <CartFlyout />
    </React.Fragment>
  );
};

const HeaderComp = () => (
  <DataProvider>{(props) => <Header {...props} />}</DataProvider>
);

export default HeaderComp;
