export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter',
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/inspiration',
      id: process.env.REACT_APP_BLOG_ID || 429,
      mainLevel: 1,
      menu: true,
    },
    stores: {
      path: '/retailers',
    },
    brand: {
      id: 150,
    },
    category: {
      hide: false,
    },
    product: {
      hide: false,
    },
  },
  settings: {
    storeName: 'Klong',
    siteContentId: 68,
  },
};
