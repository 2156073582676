import React from 'react';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import loadable from '@loadable/component';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import { config } from '../shop.config.custom';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import PagePreviewQuery from './PagePreviewQuery.gql';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import SiteContent from './SiteContent/SiteContentProvider';
import { Stores } from './Stores/Stores';
import './Theming/globalStyles';
import { theme } from './Theming/Theming';
import CustomFont from './Util/Fonts/CustomFont';
import { KlaviyoTracking } from './Util/KlaviyoTracking';

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />,
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />,
});

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />,
  }
);

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />,
  }
);

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />,
});

export const LoadableProductPage = loadable(
    () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />,
  }
);
export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />,
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />,
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />,
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />,
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />,
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />,
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />,
});

//BLOG IMPORTS
const LoadableBlogPage = loadable(() => import('./Blog/BlogPage'), {
  fallback: <LoadingPage />,
});

const LoadableBlogArticle = loadable(() => import('./Blog/BlogArticle'), {
  fallback: <LoadingPage />,
});

function Shop() {
  const { routes } = useShopConfig();
  return (
    <GenericError>
      <ModalProvider>
        <KlaviyoTracking>
          <SiteContent>
            <Container>
              <LoadingBar />
              <CustomFont
                primaryFont={theme.font.primary}
                injectCss={loadFontCss}
              />
              <Helmet
                titleTemplate={`%s - ${config.settings.storeName}`}
                defaultTitle={config.settings.storeName}
              />
              <ProductListProvider queries={productListQueries}>
                <Header />
                <Content>
                  <PaginationProvider defaultProductsPerPage={24}>
                    <Switch>
                      <Route
                        exact
                        path={`${config.routes.blog.path}/:cat/:item`}
                      >
                        <LoadableBlogArticle
                          configObject={config.routes.blog}
                        />
                      </Route>
                      <Route exact path={`${config.routes.blog.path}/:cat`}>
                        <LoadableBlogPage configObject={config.routes.blog} />
                      </Route>
                      <Route exact path={config.routes.blog.path}>
                        <LoadableBlogPage configObject={config.routes.blog} />
                      </Route>
                      <Route exact path="/">
                        <LoadableStartPage />
                      </Route>
                      <Route path={config.routes.favourites.path}>
                        <Favourites />
                      </Route>
                      <Route path={routes.search.path}>
                        <LoadableSearchPage />
                      </Route>
                      <Route path={routes.signup.path}>
                        <LoadableSignUpPage />
                      </Route>
                      <Route path={routes.login.path}>
                        <LogInPage />
                      </Route>
                      <Route path={routes.logout.path}>
                        <LogOutPage />
                      </Route>
                      <Route path={config.routes.stores.path}>
                        <Stores />
                      </Route>
                      <Route path={routes.myPages.path}>
                        <LoadableMyPages />
                      </Route>
                      <Route exact path={routes.forgotPassword.path}>
                        <ForgotPassword />
                      </Route>
                      <Route path={`${routes.resetPassword.path}/:token`}>
                        <ResetPassword />
                      </Route>
                      <Route
                        path="/preview"
                        render={(props) => (
                          <PreviewRoute
                            productQuery={ProductPreviewQuery}
                            categoryQuery={CategoryPreviewQuery}
                            pageQuery={PagePreviewQuery}
                            productPage={LoadableProductPage}
                            categoryPage={LoadableCategoryPage}
                            contentPage={LoadableContentPage}
                            StartPage={LoadableStartPage}
                            {...props}
                          />
                        )}
                      />
                      <DynamicRoute
                        routeQuery={routeQuery}
                        productPage={LoadableProductPage}
                        categoryPage={LoadableCategoryPage}
                        contentPage={LoadableContentPage}
                        notFoundPage={NotFound}
                        LoadingPage={LoadingPage}
                      />
                    </Switch>
                  </PaginationProvider>
                </Content>
                <Footer />
              </ProductListProvider>
              <ModalRoot />
              <ScrollRestorationHandler
                ignoreForRouteTypes={[
                  'sortOrderChange',
                  'filterChange',
                  'paginationChange',
                ]}
              />
            </Container>
          </SiteContent>
        </KlaviyoTracking>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
