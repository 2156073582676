//import body1 from './futura_book.woff2';
//import body2 from './futura_light.woff2';
//import body3 from './futura_light_italic.woff2';

//import body1 from './FUTUBW.TTF';
//import body2 from './FUTUBL.TTF';
//import body3 from './FUTUBLI.TTF';

import body1 from './futura_book.woff2';
import body2 from './futura_light.woff2';
import body3 from './futura_light_italic.woff2';

import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'futura';
        src: url(${body1}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'futura';
        src: url(${body2}) format('woff2');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'futura';
        src: url(${body3}) format('woff2');
        font-weight: 300;
        font-style: italic;
        font-display: swap;
      }
    }
  `;
}
